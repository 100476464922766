<template>
    <v-card outlined>
        <v-card-text>
            <v-row wrap ro align="center">
                <v-col cols="12">
                    <form autocomplete="nope" @submit.prevent="postData">
                        <v-container grid-list-md fluid>
                            <v-row dense align="center">
                                <v-col cols="12" sm="3">
                                    <v-menu
                                        ref="menu"
                                        dense
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="servicio.time"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                v-model="servicio.time"
                                                label="Hora"
                                                prepend-icon="mdi-clock-outline "
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-if="menu2"
                                            dense
                                            v-model="servicio.time"
                                            full-width
                                            @click:minute="$refs.menu.save(servicio.time)"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-autocomplete
                                        dense
                                        v-model="tipoServicioValue"
                                        v-validate="'required'"
                                        :items="tipoServicio"
                                        item-text="text"
                                        item-value="value"
                                        :error-messages="errors.collect('room')"
                                        label="SERVICIO --"
                                        data-vv-name="room"
                                        data-vv-as="Tipo"
                                        required
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-autocomplete
                                        v-if="listSlot"
                                        dense
                                        v-model="room"
                                        v-validate="'required'"
                                        :items="listSlot"
                                        item-text="rename"
                                        item-value="id"
                                        :error-messages="errors.collect('room')"
                                        label="Habitación"
                                        data-vv-name="room"
                                        data-vv-as="Tipo"
                                        required
                                        return-object
                                    />
                                </v-col>
                                <!-- :items="selectListaPrivado" -->
                                <v-col v-if="tipoServicioValue == 11 && user.id == 135" cols="12" sm="3">
                                    <v-autocomplete
                                        v-if="listSlot"
                                        dense
                                        v-model="listBySede"
                                        v-validate="'required'"
                                        :items="filterSede"
                                        item-text="sede_name_id"
                                        item-value="id"
                                        :error-messages="errors.collect('listBySede')"
                                        label="Sede"
                                        data-vv-name="listBySede"
                                        data-vv-as="Tipo"
                                        return-object
                                    />
                                    -->
                                </v-col>
                                <v-col v-if="user.idBussines == 1" cols="12" sm="5">
                                    <v-autocomplete
                                        dense
                                        v-model="servicio"
                                        v-validate="'required'"
                                        label="Servicio"
                                        :items="selectListaPrivado"
                                        data-vv-as="servicio"
                                        :error-messages="errors.collect('servicio')"
                                        data-vv-name="servicio"
                                        required
                                        item-text="description"
                                        item-value="id"
                                        return-object
                                    >
                                        <template v-slot:item="data">
                                            <!-- <v-chip small color="secondaryTheme" dark>{{data.item.room }}</v-chip> -->
                                            <span class="caption"
                                                >{{ data.item.description.toUpperCase() }} ({{ data.item.hours }}) - S/ {{ data.item.price }}</span
                                            >
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col v-else cols="12" sm="2">
                                    <v-autocomplete
                                        dense
                                        v-model="servicio"
                                        v-validate="'required'"
                                        label="Servicio"
                                        :items="datos.servicios"
                                        data-vv-as="servicio"
                                        :error-messages="errors.collect('servicio')"
                                        data-vv-name="servicio"
                                        required
                                        item-text="description"
                                        item-value="id"
                                        return-object
                                    >
                                        <template v-slot:item="data">
                                            <!-- <v-chip small color="secondaryTheme" dark>{{data.item.room }}</v-chip> -->
                                            <span class="caption"
                                                >{{ data.item.description.toUpperCase() }} ({{ data.item.hours }}) - S/ {{ data.item.price }}</span
                                            >
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="3" v-if="_isDiscount">
                                    <v-text-field dense v-model="servicio.descuentos" label="Descuentos (-)" />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field dense v-model="servicio.comision" label="Comisión POS (+)" />
                                </v-col>
                                <v-col v-if="tipoServicioValue == 11" cols="12" sm="3">
                                    <v-autocomplete
                                        v-if="listSlot"
                                        dense
                                        v-model="masajista"
                                        v-validate="'required'"
                                        :items="listMasseuse"
                                        item-text="nombres"
                                        item-value="nombres"
                                        :error-messages="errors.collect('masajista')"
                                        label="Masajista"
                                        data-vv-name="masajista"
                                        data-vv-as="Tipo"
                                        return-object
                                    />
                                </v-col>
                                <v-col v-if="user.idBussines != 1" cols="12" sm="3">
                                    <v-text-field dense v-model="servicio.descuentos" label="Descuentos (-)" />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field dense v-model="itemTotalProduct" prefix="S/." filled rounded label="Sub total" readonly />
                                </v-col>
                                <v-col cols="12" sm="2" class="text-center">
                                    <v-btn block color="secondaryTheme" small dark type="submit">agregar</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </form>
                </v-col>
                <v-col cols="12" class="grey lighten-5">
                    <v-data-table multi-sort :headers="headers" :items="listServiceItems" class="elevation-1" :items-per-page="20" item-key="index">
                        <template v-slot:[`item.action`]="{ item, index }">
                            <v-icon color="primary" small @click="deleteItem(item, index)">mdi-delete </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { setMinutes, addHours, addMinutes, setHours, format, parseISO } from 'date-fns'
import DefaultForm from '@/utils/defaultForm'

export default {
    filters: {},
    props: ['lista', 'service'],
    $_veeValidate: {
        validator: 'new'
    },
    data: () => ({
        room: null,
        listBySede: null,
        menu2: false,
        masajista: null,
        servicio: DefaultForm.formSelectServicio(),
        selectListaPrivado: [],
        listSlot: null,
        isSelect: false,
        tipoServicioValue: null,
        quantity: 1,
        tipoServicio: [
            { text: 'MIXTO', value: 10 },
            { text: 'MASAJE', value: 11 },
            { text: 'PRIVADOS', value: 12 },
            { text: 'PAQUETES', value: 0 }
        ],
        time: format(new Date(), 'HH:mm'),
        listaServicios: [],
        headers: [
            { text: 'SLOT', value: 'rename' },
            { text: 'Codigo', value: 'internal_id' },
            { text: 'Descripción', value: 'description' },
            { text: 'Duración', value: 'hoursTime' },
            { text: 'Masajista', value: 'labelMasajista' },
            { text: 'UNID', value: 'quantity' },
            { text: 'P. UNIT (S/)', value: 'price' },
            { text: 'Comision', value: 'comision' },
            { text: 'Descuento', value: 'descuentos' },
            { text: 'SUBTOTAL', value: 'subTotal' },
            { text: 'Actions', value: 'action', sortable: false }
        ]
    }),
    computed: {
        ...mapState('ventas', ['datos', 'privados', 'masajes', 'mixtos', 'servicios']),
        ...mapState('privados', ['listaHab', 'listaPrecio', 'listService']),
        ...mapState('masajes', ['listaMassage']),
        ...mapState('sedes', ['sedes']),
        ...mapState('mixtos', ['listaMixto']),
        ...mapState('users', ['user', 'allColaborador']),

        ...mapGetters('privados', ['listHabAvailable']),
        ...mapGetters('users', ['optionalRole']),

        filterSede() {
            const sedeBussiness = this.sedes.filter((x) => x.idBussines == 1)
            return sedeBussiness
        },
        _isDiscount() {
            return this.optionalRole?.isDiscount
        },
        listMasseuse() {
            const findSede = this.listBySede ? this.listBySede.id : this.user.idSede
            const masajista = this.allColaborador.filter((x) => x.status == 1 && x.idBussines == 1 && x.idSede == findSede)
            console.log('masajista---', masajista)
            return masajista
        },
        itemTotalProduct() {
            let val = 0
            //console.log('this.servicio', this.servicio)
            const { descuentos, comision, price, unidades } = this.servicio
            if (this.servicio) {
                val = (
                    this._parseFloat(price) * this._parseFloat(unidades) +
                    this._parseFloat(comision) * this._parseFloat(unidades) -
                    this._parseFloat(descuentos)
                ).toFixed(2)
            }
            return val
        },
        listServiceItems() {
            const masajes = []
            const privados = []
            //console.log('this.privados', this.privados)
            this.privados.map((x) =>
                privados.push({
                    ...x,
                    hoursTime: x.hours,
                    internal_id: x.internal_id || '-',
                    labelMasajista: x.masajista?.nombres ? x.masajista.nombres : '-'
                })
            )
            console.log('privados', privados)
            this.masajes.map((x) =>
                masajes.push({
                    ...x,
                    rename: x.massage,
                    description: x.description,
                    hoursTime: x.hours,
                    quantity: x.quantity,
                    price: x.price,
                    comision: x.comision,
                    descuentos: x.descuentos,
                    subTotal: x.subTotal
                })
            )
            const mixtos = []
            this.mixtos.map((x) =>
                mixtos.push({
                    ...x,
                    rename: x.mixto,
                    description: x.description,
                    hoursTime: x.hours,
                    quantity: x.quantity,
                    price: x.price,
                    comision: x.comision,
                    descuentos: x.descuentos,
                    subTotal: x.subTotal
                })
            )
            console.log('mixtos', mixtos)
            const services = privados.concat(masajes).concat(mixtos)
            console.log('privados.concat(masajes).concat(mixtos)', services)
            return services
        },

        _tipoServicioValue() {
            //console.log('resturn')
            return this.tipoServicioValue
        }
    },
    watch: {
        room() {
            let items = []
            this.servicios.map((val) => {
                /* //console.log(item.id + "..." + val.id_hab_sede); */
                items.push({
                    ...val,
                    comision: 0,
                    descuentos: 0,
                    unidades: 1,
                    time: format(new Date(), 'HH:mm')
                })
            })
            //console.log('items', items)
        },
        async _tipoServicioValue(val) {
            let itemsList
            this.room = null
            await this.$store.dispatch('privados/servicios', { idCategoria: Number(val), active: 0 })
            let items = []
            console.log('listService', this.listService)
            console.log('val', val)
            this.listService.map((x) => {
                items.push({
                    ...x,
                    comision: 0,
                    descuentos: 0,
                    unidades: 1,
                    time: format(new Date(), 'HH:mm')
                })
            })
            if (val === 10) {
                itemsList = items.filter((x) => x.category_id === val)
            } else if (val === 11) {
                itemsList = items.filter((x) => x.category_id === val)
            } else if (val === 12) {
                itemsList = items.filter((x) => x.category_id === val)
            } else {
                itemsList = items.filter((x) => x.is_set)
            }
            console.log('itemsList', itemsList)
            this.selectListaPrivado = itemsList
            const newListMixto = this.listaMixto.map((x) => ({ rename: x.mixto, ...x }))
            const newListMasaje = this.listaMassage.map((x) => ({ rename: x.massage, ...x }))
            const newListPrivado = this.listaHab.map((x) => ({ rename: x.room, ...x }))

            switch (Number(val)) {
                case 10:
                    this.listSlot = newListMixto
                    break
                case 11:
                    this.listSlot = newListMasaje
                    break
                case 12:
                    this.listSlot = newListPrivado
                    break
                default:
                    this.listSlot = null
                    break
            }
        }
    },
    async mounted() {
        await this.$store.dispatch('users/allColaborador')
        await this.$store.dispatch('sedes/getAllSedes')
        this.room = this.listaHab[0]
        this.masaje = this.listaMassage[0]
        this.mixto = this.listaMixto[0]
        this.$store.dispatch('privados/servicios', { active: 0 })
        this.tipoServicioValue = 10
    },
    methods: {
        _parseFloat(val) {
            return parseFloat(val) || 0
        },
        deleteItem(item, index) {
            // //console.log(item);
            this.privados.splice(index, 1)
            // this.$store.commit('ventas/UPDATE_PRIVADOS', lista.splice(indexItem, 1))
        },
        postData() {
            console.log('thisdatos-', this.datos)
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    const getH = (val) => parseInt(val.slice(0, 2))
                    const getM = (val) => parseInt(val.slice(3, 5))
                    const hour = getH(this.servicio.time)
                    const minutos = getM(this.servicio.time)
                    const hourDuration = getH(this.servicio.hours ? this.servicio.hours : '01:00')
                    const minutosDuration = getM(this.servicio.hours ? this.servicio.hours : '01:00')
                    let check_in_time = setMinutes(parseISO(this.datos.check_in), minutos)
                    check_in_time = setHours(check_in_time, hour)
                    let check_out_time = null
                    for (let index = 0; index < this.quantity; index++) {
                        check_out_time = addHours(check_in_time, hourDuration)
                        check_out_time = addMinutes(check_out_time, minutosDuration)
                    }
                    const listaServicios = this.privados
                    const checkoutoutTime = format(check_out_time, 'yyyy-MM-dd HH:mm')
                    const checkoutinTime = format(check_in_time, 'yyyy-MM-dd HH:mm')
                    console.log('this.room', this.room)
                    listaServicios.push({
                        timestamp: Date.now(),
                        idRoom: this.room ? this.room.id : null,
                        room: this.room ? this.room.rename : null,
                        ...this.servicio,
                        masajista: this.masajista
                            ? {
                                  id: this.masajista.id,
                                  nombres: this.masajista.nombres,
                                  apellidos: this.masajista.apellidos
                              }
                            : null,
                        description: this.servicio.description,
                        hoursTime: this.servicio.hours,
                        quantity: this.quantity,
                        subTotal: this.itemTotalProduct,
                        idTipoServicio: this.tipoServicioValue,
                        check_out_time: checkoutoutTime,
                        check_in_time: checkoutinTime
                    })
                    this.$store.commit('ventas/UPDATE_PRIVADOS', listaServicios)
                    this.servicio = DefaultForm.formSelectServicio()
                }
            })
        }
    }
}
</script>
<style></style>
